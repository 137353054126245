<template>
  <div id="app">
    <div class="shopro-header-contaner">
      <van-image
        width="100%"
        height="184px"
        fit="cover"
        :src="bannerImage"
      ></van-image>
      <div class="back-icon" @click="goBack">
        <van-icon name="arrow-left" />
      </div>
      <div
        class="shopro-header-title"
        v-if="
          this.$route.path == '/index' ||
          this.$route.path == '/' ||
          this.$route.path == '/req'
        "
      >
        <div v-if="this.$route.path != '/req'">参观预约</div>
        <div v-if="this.$route.path == '/req'">查询预约</div>
        <div class="shopro-header-line"></div>
      </div>
    </div>
    <router-view />
  </div>
</template>
<script>
export default {
  name: "Home",
  data() {
    return {
      bannerImage: "",
    };
  },
  mounted() {
    this.getInit();
    console.log(this.$route.path);
  },
  methods: {
    getInit() {
      let that = this;
      that.$axios.get("https://huanghe.xprings.com/api/apply/init").then((res) => {
        that.bannerImage = res.data.data.ads.image;
      });
    },
    goBack() {
      this.$router.back(-1);
    },
  },
};
</script>

<style lang="scss">
#app {
  height: 100vh;
  position: relative;
  font-family: Noto Sans S Chinese;
  .shopro-header-contaner {
    position: relative;
  }
  .back-icon {
    position: absolute;
    top: 20px;
    left: 11px;
    color: #fff;
    font-size: 20px;
  }
  .shopro-header-title {
    font-size: 26px;
    font-weight: bold;
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -60px;
    margin-top: -13px;
    width: 120px;
    text-align: center;
    height: 26px;
  }
  .shopro-header-line {
    width: 26px;
    height: 4px;
    background: #ffffff;
    border-radius: 2px;
    margin: 16px 47px;
  }

  .van-cell {
    padding: 10px 0;
    font-size: 13px;
    color: #333;
    border-bottom: 1px solid #eee !important;
  }
  .van-field__control {
    background: none !important;
  }
  .van-field__label {
    text-align: justify;
    text-align-last: justify;
  }

  .van-cell::after {
    border-bottom: none !important;
  }
}
</style>
