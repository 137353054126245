import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: () =>
      import("../views/Index.vue")
  },
  {
    path: "/error",
    name: "Error",
    component: () =>
      import("../views/Error.vue")
  },
  {
    path: "/success",
    name: "Success",
    component: () =>
      import("../views/Success.vue")
  },
  {
    path: "/req",
    name: "Req",
    component: () =>
      import("../views/Req.vue")
  },
];

const router = new VueRouter({
  routes
});

export default router;
